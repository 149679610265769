import React from "react";


const Custombl = () => {
    return (
        <div className="h-auto w-full flex justify-center items-center">
            <div className="md:w-[90%] w-[90%] h-auto flex justify-center">
                <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Imagen1.png?alt=media&token=070deeda-cf02-491b-8df2-cdad3efab247" alt="flayer" className="w-[90%] h-auto" />
            </div>

        </div>
    );
}

export default Custombl;